var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',[_c('b-button',{staticClass:"mb-0",attrs:{"pill":"","block":"","variant":"outline-success"},on:{"click":function($event){$event.preventDefault();return _vm.pushNewUser($event)}}},[_c('b-icon-plus'),_vm._v(" Novo usuário")],1)],1),_c('div',{staticClass:"mt-3"},[(_vm.loadingUsers)?_c('div',[_c('b-skeleton',{attrs:{"animation":"throb","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"throb","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"throb","width":"70%"}})],1):_c('b-list-group',_vm._l((_vm.users),function(user){return _c('b-list-group-item',{key:user.id,staticClass:"flex-column align-items-start border-0",attrs:{"href":"#","variant":user.active == 0 ? 'danger' : ''},on:{"click":function($event){$event.preventDefault();_vm.current = Object.assign({},user)}}},[_c('div',{staticClass:"d-flex w-100 align-items-center justify-content-between"},[_c('h5',{staticClass:"mb-1 mr-1"},[_vm._v(_vm._s(user.name)),(user.active == 0)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":"Desativado"}},[_c('b-icon-lock')],1):_vm._e()]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"title":user.level === 'admin'
                                    ? 'Administrador'
                                    : 'Atendente'}},[_c('b-icon-circle-fill',{attrs:{"variant":user.level === 'admin'
                                        ? 'primary'
                                        : 'success'}})],1)]),_c('small',[_vm._v(" "+_vm._s(user.email))])])}),1)],1)]),_c('b-col',{staticClass:"border-left"},[_c('b-alert',{attrs:{"show":_vm.current.id < 0}},[_c('b-icon-info-circle-fill',{staticClass:"mr-2"}),_vm._v(" Clique sobre um usuário para editar ou em novo para adicionar ")],1),_c('b-overlay',{attrs:{"show":_vm.savingUser,"rounded":"sm"}},[(_vm.current.id >= 0)?_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveUser($event)}}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('b-form-input',{attrs:{"required":"","placeholder":"Nome completo"},model:{value:(_vm.current.name),callback:function ($$v) {_vm.$set(_vm.current, "name", $$v)},expression:"current.name"}})],1),_c('p',{staticClass:"bold"},[_vm._v("Dados de acesso")]),_c('b-form-group',{attrs:{"label":"E-mail (Login)"}},[_c('b-form-input',{attrs:{"required":"","readonly":_vm.current.id > 0,"placeholder":"E-mail usado como login"},model:{value:(_vm.current.email),callback:function ($$v) {_vm.$set(_vm.current, "email", $$v)},expression:"current.email"}})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Senha"}},[_c('b-form-input',{attrs:{"type":"password","autocomplete":"off","required":_vm.current.id === 0,"placeholder":"******"},model:{value:(_vm.current.password),callback:function ($$v) {_vm.$set(_vm.current, "password", $$v)},expression:"current.password"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Perfil"}},[_c('b-form-select',{attrs:{"required":"","options":[
                                    {
                                        text: 'Administrador',
                                        value: 'admin',
                                    },
                                    { text: 'Atendente', value: 'user' } ]},model:{value:(_vm.current.level),callback:function ($$v) {_vm.$set(_vm.current, "level", $$v)},expression:"current.level"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Ativo"}},[_c('b-form-select',{attrs:{"required":"","options":[
                                    { text: 'Sim', value: 1 },
                                    { text: 'Não', value: 0 } ]},model:{value:(_vm.current.active),callback:function ($$v) {_vm.$set(_vm.current, "active", $$v)},expression:"current.active"}})],1)],1)],1),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Salvar")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }