































































































































import Vue from 'vue'
import api, { routers } from '@/api'
import { notification } from '@/utils'

interface IUser {
    id: number
    name: string
    email: string
    level: string
    password?: string
    active?: number
}

export default Vue.extend({
    data: () => ({
        loadingUsers: false,
        savingUser: false,
        users: [] as Array<IUser>,
        current: {
            id: -1,
        } as IUser,
    }),
    methods: {
        clear() {
            this.current = {
                id: -1,
                name: '',
                email: '',
                level: 'user',
                active: 1,
                password: '',
            }
        },
        pushNewUser() {
            this.clear()
            this.current.id = 0
        },
        async saveUser() {
            try {
                this.savingUser = true
                const { data } = await api.post(routers.Users.ADD_USER, this.current)
                if (this.current.id > 0) {
                    //editando
                    this.users[this.users.findIndex(u => u.id === data.id)] = data
                } else {
                    //Novo
                    this.users.push(data)
                }
                this.clear()
            } catch (err) {
                notification.toastTryCatchError(err)
            } finally {
                this.savingUser = false
            }
        },
    },
    async mounted() {
        try {
            this.loadingUsers = true
            const { data } = await api.get(routers.Users.LIST_USERS)
            this.users = data
        } catch (err) {
            console.log(err)
        } finally {
            this.loadingUsers = false
        }
    },
})
